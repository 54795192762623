@import '../styles/layout';

.cookieDetails {
  h4 {
    font-size: 120%;
    margin: 0 0 10px 0 !important;
  }

  a {
    font-size: 90%;
    text-decoration: underline;
    color: $neutral_light;
  }
}

.root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;

    .importantInfo {
        position: fixed;
        z-index: 1000;
        border: solid 10px #DA8C9A;
        padding: 60px 20px 40px 20px;
        background-color: $neutral-light;
        color: $neutral-dark;
        font-size: 120%;
        font-weight: 900;
        left: 50%;
        top: 30%;
        transform: translate(-50%, 0);
        width: 300px;
        text-align: center;

        .closeImportantInfo {
            position: fixed;
            top: -10px;
            right: -10px;
        }
    }

    .floatingMenu {
        position: fixed;
        top: 10px;
        right: 10px;
        display: none;
        display: flex;
        align-items: center;
        z-index: 1000;

        a {
            display: none;
            height: 30px;
        }

        .circleWrapper {
            display: flex;
            justify-content: center;
            box-shadow: 0px 0px 20px 2px;
            margin-left: 20px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $neutral-dark;
            color: $neutral-dark;
        }
    }

    header {
        background-color: $header-bg;
        color: $header-font;

        .menu {
            display: flex;
            justify-content: flex-end;
            padding: 10px 0 0 0;
        }
    }

    .content {
        flex: 1 0 auto;

        .title {
            flex: 1 0 auto;
            background-color: $title-bg;
            padding: 60px 0 20px 0;

            .wrapper {
                display: flex;
                align-items: flex-end;
                border-bottom: solid 4px $neutral-dark;

                h1 {
                    color: $title-font;
                    margin: 0 0 0 10px;
                }

                h2 {
                    color: $neutral-dark;
                    margin: 0;
                    margin: 0 0 0 10px;
                    text-align: left;
                }

                h3 {
                    color: $neutral-dark;
                    margin: 0;
                    margin: 0 0 0 10px;
                    text-align: left;
                }

                @media screen and (max-width: 600px) {
                    h1 {
                        font-size: 8vw;
                    }

                    h2 {
                        font-size: 5vw;
                    }

                    h3 {
                        font-size: 4vw;
                    }
                }

                .image {
                    background-image: url('../images/DSCF4913.jpg');
                    background-repeat: no-repeat;
                    background-size: auto 264px;
                    background-position: left;
                    width: 140px;
                    height: 200px;
                }

                @media screen and (max-width: 600px) {
                    .image {
                        background-size: auto 150px;
                        width: 80px;
                        height: 100px;
                    }
                }

                .text {
                    padding: 0 0 10px 0;
                }
            }
        }
    }

    footer {
        background-color: $footer-bg;
        color: $footer-font;
        padding: 10px 0 0 0;
        flex-shrink: 0;
        width: 100%;

        a {
            color: $footer-font;
        }

        .info {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .item, .social {
                min-width: 260px;
                margin: 5px 10px;

                > svg {
                    margin: 0 10px 0 0;
                    color: $footer-social;
                }

                .title {
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }
}
