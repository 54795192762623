@import './shared';

h2.attention {
 color: $primary;
}

h3.attention {
  color: $primary;
  margin: 30px 0 10px 10px;
}

h3.mix {
    display: block;
}

span.attention {
  color: $primary;
}