@import '../../styles/shared';

.root {
  form {
    padding: 0px 10px 0 10px;

    .small {
      width: 400px;
    }

    > div {
      margin: 40px 0px;
    }
  }

  .bookBtn {
    display: flex;

    .success {
      padding: 0 0 0 10px;
      font-size: 20px;
      display: flex;
      color: $primary;
      align-items: center;
    }

    .failed {
      padding: 0 0 0 10px;
      font-size: 20px;
      display: flex;
      color: $primary;
      align-items: center;
    }
  }

  .allModules{
    margin: 10px 0 0 0;
    span {
      font-weight: bold;
    }
  }

  .modules {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 20px;
    span {
    }
  }

  .total {
    font-weight: bold;
    font-size: 140%;
  }
}