@import '../../styles/frontpage';

h2 {
  color: $primary;
}


.bgImg {
  background-image: url('../../images/DSCF4913.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-position: right;


  @media screen and (max-width: 1600px) {
    background-position: right -50px top 80px;
    background-size: auto 700px;
  }

  @media screen and (max-width: 640px) {
    background-attachment: inherit;
    background-position: right -150px top 120px;
    background-size: auto 570px;
  }
  /*
  @media screen and (max-width: 640px) {
    background-attachment: inherit;
    background-position: right -220px top 30px;
    background-size: auto 140vw;
  }

  @media screen and (max-width: 500px) {
    background-attachment: inherit;
    background-position: right -170px top 80px;
    background-size: auto 160vw;
  }
  */
}

.sectionIntro {
}

.intro {
  position: relative;
  padding: 40px 0;
  height: 600px;

  @media screen and (max-width: 1600px) {
    height: 600px;
  }

  @media screen and (max-width: 640px) {
    height: 500px;
  }

  .introduction {

    h1 {
      color: $title-font;
      margin: 0 0 0 10px;
      font-size: 80px;
    }

    h2 {
      color: $sub-title-font;
      margin: 0;
      margin: 0 0 0 10px;
      text-align: left;
      font-size: 40px;
    }

    h3 {
      margin-bottom: 0;
      margin-left: 4px;
    }

    @media screen and (max-width: 700px) {
      h1 {
        font-size: 10vw;
      }

      h2 {
        font-size: 5vw;
      }
    }

    p {
      margin: 60px 0;
    }
  }

  .description {
    position: absolute;
    padding: 50px 0 0 0px;
    width: 50%;
    color: $description-font;
    font-size: 50px;

    @media screen and (max-width: 700px) {
      font-size: 28px;
      width: 50%;
    }
  }

  .contact {
    position: absolute;
    bottom: 20px;

    a {
      font-size: 26px;
      margin: 0 0 0 12px;
      font-weight: 900;

      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
    }
  } 
}

.mindfulmor {
  background-color: #DA8C9A;
  box-shadow: 0 0 20px 4px;
  position: relative;
  color: $neutral-dark;

  h2 {
    color: $neutral_light;
  }

  h3 {
    display: flex;
    align-items: center;
  }

  p {
    color: $neutral-light;
    font-size: 20px;
    font-weight: 400;
  }

  a {
    margin: 0 10px;
    background-color: $neutral-dark;
  }

  a:hover {
    background-color: $neutral-dark;
  }
}

.secondary {
  background-color: #DA8C9A;
  box-shadow: 0 0 20px 4px;
  position: relative;
  color: $neutral-dark;

  h2 {
    color: $neutral_light;
  }

  h3 {
    display: flex;
    align-items: center;
  }

  p {
    color: $neutral-light;
    font-size: 20px;
    font-weight: 400;
  }

  a {
    margin: 0 10px;
    background-color: $neutral-dark;
  }

  a:hover {
    background-color: $neutral-dark;
  }
}

.primary {
    background-color: #8DB5AB;
    box-shadow: 0 0 20px 4px;
    position: relative;
    color: $neutral-dark;

    h2 {
        color: $neutral_light;
    }

    h3 {
      display: flex;
      align-items: center;
    }

    p {
        color: $neutral-light;
        font-size: 20px;
        font-weight: 400;
    }

    a {
        margin: 0 10px;
        color: #619789 !important;
        border-color: #619789 !important;
        background-color: $neutral-light;
    }

    a:hover {
        background-color: $neutral-light;
        border-color: #C3D9D4 !important;
    }
}

.neutral {
  background-color: $neutral-dark;
  box-shadow: 0 0 20px 4px;
  position: relative;
  color: $neutral-dark;

  h2 {
    color: $neutral_light;
  }

  h3 {
    display: flex;
    align-items: center;
  }

  p {
    color: $neutral-light;
    font-size: 20px;
    font-weight: 400;
  }

  a {
    margin: 0 10px;
  }

  .product {
  }
}

.spacer {
  height: 40px;
}

.recommendations {
  /*
  background-position: center;
  background-image: url('../../images/intro_2.jpg');
  background-size: cover;
   */
  color: $recommendations-font;
  background-attachment: fixed;
  background-color: $recommendations-bg;

  @media screen and (max-width: 1000px) {
    background-attachment: inherit;
  }

  h2 {
    color: $recommendations-header;
  }

  li {
    background-color: $neutral-dark;
  }


  .item {
    margin: 20px 10px;
    font-size: 18px;
    text-align: left;

    .icon {
      margin: 0 10px -8px 0;
      height: 30px;
      width: 30px;
      color: $recommendations-icon;
    }
  }
}
/*
@media screen and (min-width: 1200px) {

  .root {

    > p {
      font-size: 36px;
    }

    .photo-iben {

    }
  }
}
*/
