@import '../../styles/shared';

.mindfulmorv2 {
  li {
    padding: 10px 0;

  }

  h1 {
    font-size: 42px;
    color: $neutral-dark;
    font-weight: normal;

    div {
      font-size: 42px;
      font-weight: 800;
      white-space: nowrap;
    }

  }

  strong {
    color: #DA8C9A;
    font-size: 120%;

  }

  a.externalRef {
    color: #8DB5AB;
    font-weight: bold;
  }

  a.externalRef:hover {
    text-decoration: underline;
  }

  .aboutMe {
    padding: 100px 0 0 0;

    img {
      width: calc(100% - 20px);
      padding: 0 10px;
    }

    a {
      margin: 0 0 0 10px;
    }

    div {
      padding: 0 10px;
    }
  }

  .centered {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .signature {
    color: #DA8C9A;
    font-size: 105%;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: calc(100% - 20px);
      max-width: 600px;
    }

  }

  .primary {
    background-color: #8DB5AB;
    box-shadow: 0 0 20px 4px;
    position: relative;
    color: $neutral-dark;

    h2 {
      color: $neutral_light;
    }

    h3 {
      display: flex;
      align-items: center;
      color: $neutral_light;
    }

    p {
      color: $neutral-light;
      font-size: 20px;
      font-weight: 400;
    }

    a {
      margin: 0 10px;
      color: #619789 !important;
      border-color: #619789 !important;
      background-color: $neutral-light;
    }

    a:hover {
      background-color: $neutral-light;
      border-color: #C3D9D4 !important;
    }
  }

  .secondary {
    background-color: #DA8C9A;
    box-shadow: 0 0 20px 4px;
    position: relative;
    color: $neutral-dark;

    h2 {
      color: $neutral_light;
    }

    h3 {
      display: flex;
      align-items: center;
      color: $neutral_light;
    }

    p {
      color: $neutral-light;
      font-size: 20px;
      font-weight: 400;
    }

    a {
      margin: 0 10px;
      color: #943243 !important;
      border-color: #943243 !important;
      background-color: $neutral-light;
    }

    a:hover {
      background-color: $neutral-light;
      border-color: #D46A6A !important;
    }
  }

  .carouselWrapper {
    padding: 20px 20px;

    .carouselItem {
      margin: 10px 10px 40px 10px;
      border-style: solid;
      border-width: 10px 10px 10px 10px;
      border-color: $neutral-dark;
      position: relative;
      box-shadow: 0 0 2px 2px;
      color: $neutral-dark;
      border-radius: 20px;

      img {
        margin: auto;
        display: block;
        width: 80%;

      }
    }
  }
}

.mindfulmor {

  li {
    padding: 10px 0;

  }

  .introLayout {
    > div {
      display: flex;
      justify-content: center;
    }

    .introWrapper {


      h2 {
        color: $neutral-light;
        font-weight: 800;
      }

      h3 {
        color: $neutral-light;
        display: flex;
      }

      p {
        margin: 0;
        font-size: 120%;
        font-weight: 800;
        color: $neutral-light;
      }

      .primary {
        box-shadow: 0 0 20px 4px;
        padding: 20px 20px;
        margin: 20px 80px 20px 20px;
        border-radius: 20px;
        background-color: #8DB5AB;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;
        max-width: 400px;

        h2 {
          margin: 10px 10px 30px 10px;
          color: $neutral_dark;
        }

        h3 {
          margin: 20px 10px;
          display: flex;
          align-items: center;
          color: $neutral_light;
        }

        p {
          color: $neutral-light;
          font-size: 20px;
          font-weight: 400;
        }

        a {
          margin: 0 10px;
          color: #619789 !important;
          border-color: #619789 !important;
          background-color: $neutral-light;
        }

        a:hover {
          background-color: $neutral-light;
          border-color: #C3D9D4 !important;
        }
      }

      .secondary {
        box-shadow: 0 0 20px 4px;
        padding: 20px 20px;
        margin: 20px 20px 20px 80px;
        border-radius: 20px;
        background-color: #DA8C9A;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;
        max-width: 600px;

        h2 {
          margin: 10px 10px 30px 10px;
          color: $neutral_dark;
        }

        h3 {
          margin: 20px 10px;
          display: flex;
          align-items: center;
          color: $neutral_light;
        }

        p {
          color: $neutral-light;
          font-size: 20px;
          font-weight: 400;
        }

        a {
          margin: 0 10px;
          color: #943243 !important;
          border-color: #943243 !important;
          background-color: $neutral-light;
        }

        a:hover {
          background-color: $neutral-light;
          border-color: #D46A6A !important;
        }
      }
    }
  }

  .priceLayout {
    > div {
      display: flex;
      justify-content: center;
    }

    .priceWrapper {
      box-shadow: 0 0 20px 4px;
      position: relative;
      color: $neutral-dark;
      max-width: 400px;
      padding: 20px 20px;
      text-align: center;
      margin: 0 0px;
      border-radius: 20px;

      .primary {
        background-color: #8DB5AB;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;

        h2 {
          color: $neutral_light;
        }

        h3 {
          display: flex;
          align-items: center;
          color: $neutral_light;
        }

        p {
          color: $neutral-light;
          font-size: 20px;
          font-weight: 400;
        }

        a {
          margin: 0 10px;
          color: #619789 !important;
          border-color: #619789 !important;
          background-color: $neutral-light;
        }

        a:hover {
          background-color: $neutral-light;
          border-color: #C3D9D4 !important;
        }
      }

      .secondary {
        background-color: #DA8C9A;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;

        h2 {
          color: $neutral_light;
        }

        h3 {
          display: flex;
          align-items: center;
          color: $neutral_light;
        }

        p {
          color: $neutral-light;
          font-size: 20px;
          font-weight: 400;
        }

        a {
          margin: 0 10px;
          color: #943243 !important;
          border-color: #943243 !important;
          background-color: $neutral-light;
        }

        a:hover {
          background-color: $neutral-light;
          border-color: #D46A6A !important;
        }
      }
    }
  }

  .imageGrid {
    padding: 10px 10px 20px 10px;
    display: flex;
    justify-content: center;

    > div {
      width: 50%;
    }
  }

  .image {
    padding: 10px 10px 20px 10px;
    width: 95vw;
    max-width: 1200px;
    text-align: center;

    img {
      max-width: 800px;
    }
  }

  .carouselWrapper {
    padding: 20px 20px;

    .carouselItem {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .imageFloating {
    float: left;
    max-width: 400px;
    padding: 10px 10px;

    img {
      width: 100%;
    }
  }

  .centered {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
}
