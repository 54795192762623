@import '../../styles/shared';

.textIcon {
  margin-bottom: -5px;
}

.module {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 0 0 10px 20px;
}

span{
  svg{
    margin: 0 0 -4px 0;
  }
}