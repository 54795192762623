
.playerWrapper {
  width: 50%;
  margin: 0 0 0 25%;
}

@media screen and (max-width: 600px) {
  .playerWrapper {
    width: 80%;
    margin: 0 0 0 10%;
  }
}