@import '../../styles/shared';

.signup {
    border: 0;
    width: 100%;
}

.newsletter {
    .introduction {
        background-color: #8DB5AB;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;

        h2 {
            color: $neutral_light;
        }

        p {
            color: $neutral-light;
            font-size: 20px;
            font-weight: 400;
        }

        a {
            margin: 0 10px;
            color: #619789 !important;
            border-color: #619789 !important;
            background-color: $neutral-light;
        }

        a:hover {
            background-color: $neutral-light;
            border-color: #C3D9D4 !important;
        }
    }

    .product {
        background-color: #DA8C9A;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;

        h2 {
            color: $neutral_light;
        }

        h3 {
            display: flex;
            align-items: center;
            color: $neutral_light;
        }

        p {
            color: $neutral-light;
            font-size: 20px;
            font-weight: 400;
        }

        a {
            margin: 0 10px;
            background-color: $neutral-dark;
        }

        a:hover {
            background-color: $neutral-dark;
        }
    }

    .headerGroup {
        h2 {
            margin-bottom: 10px;
        }

        h3 {
            margin-top: 0px;
            margin-bottom: 30px;
            font-weight: normal;
        }
    }

    .confirm {
        display: flex;
        justify-content: center;
    }

    .signup {
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            padding: 30px 0 0 0;

            button {
                margin: 20px 0 0 0;
                width: 300px;
            }
        }

        h3 {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .success {
            padding: 10px 0 0 10px;
            font-size: 20px;
            display: flex;
            color: $primary;
            align-items: center;
            width: 350px;
        }
    }

    .image {
        padding: 10px 10px 20px 10px;
        width: 95vw;
        max-width: 1200px;
        text-align: center;

        img {
            border-radius: 20px;
            max-width: 800px;
        }
    }

    .centered {
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }

    .signature {
        font-size: 105%;
        margin: 50px 0 50px 0;
        
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
        p {
            text-align: center;
            margin: 5px 0;
            
            strong {
                color: #DA8C9A;
                font-size: 150%;
            }
            
            svg {
                margin-bottom: -5px;
            }
        }

        img {
            width: calc(100% - 20px);
            max-width: 300px;
        }

        a {
            font-size: 105%;
            font-weight: bold;
            color: #DA8C9A;
            text-decoration: underline
        }
        
        a:hover {
            color: #619789;

        }

        span {
            color: #619789;
            font-weight: bold;
            font-size: 105%;

        }
    }

        .guides {
            color: #DA8C9A;
            font-size: 100%;
            margin: 50px 0 50px 0;
    
            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
    
            p {
                text-align: center;
                margin: 5px 0;
    
                strong {
                    font-size: 150%;
                }
            }
    
            img {
                width: calc(100% - 20px);
                max-width: 300px;
            }
        }


}
