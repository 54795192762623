$neutral_light: #FFFFFD;
$neutral-dark: #555555;

$primary: #DA8C9A; //#d0838f; //#e38f9e;//#BD596B;
$primary_light: #F2BDC6; //#F9DEE3;


html, body {
  font-family: 'proxima-nova', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  color: $neutral-dark;
  background-color: $neutral_light;
  line-height: 1.5;
}


h1 {
  color: $primary;
  font-weight: 700;
  margin-left: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 60px;
  line-height: 1;
}

h2 {
  color: $neutral-dark;
  font-weight: 700;
  font-size: 30px;
  margin: 30px 10px 10px 10px;
  font-family: Arial, Helvetica, sans-serif;;
  line-height: 1;
}

h3 {
  color: $neutral-dark;
  font-size: 24px;
  font-weight: 800;
  margin: 40px 0 10px 10px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}

hr {
  margin: 1px;
  background-color: $neutral-dark;
  height: 1px;
}

a {
  color: $neutral-dark;
  text-decoration: none;
}

a:hover {
  //color: $primary;
  //background-color: rgba($primary, 0.2) !important;
}

p {
  padding: 0 10px;

  b {
    font-size: 110%;
  }
}

li {
  padding: 4px 0;

  b {
    font-size: 110%;
  }
}

#gatsby-focus-wrapper, #___gatsby {
  height: 100%;
  margin: 0;
  padding: 0;
}

.carousel-caption {
  position: relative !important;
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  color: inherit !important;
}