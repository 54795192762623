@import '../../styles/shared.scss';

.wrapper {
    display: flex;
    justify-content: center;
}
.survey {
    max-width: 400px;
    padding: 0 0 20px 0;

    .introduction, .question, .result {
        height: 350px;
    }

    p {
        padding: 0 20px;
    }

    h3 {
        padding: 0 10px 30px 10px;
        height: 100px;
        color: $primary;
    }

    .introduction {
        .reference {
            font-size: 70%;
            font-style: italic;
        }

    }

    .question {
        h3 {
            height: 170px;
        }
        .scaleLabels {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            font-weight: 900;
            font-size: 110%;

            div:last-child {
                text-align: right;
            }
        }

        .scaleValues {
            display: flex;
            justify-content: space-between;

        }
    }

    .result {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h2 {
            font-size: 80px;
            margin: 0 0 20px 0;
        }
        h3 {
            height: auto;
            padding: 0 10px 0px 10px;
        }

        .description {
            width: 100%;
            margin: 0;
            padding: 0 20px 0 20px;

            p {
                margin: 10px 0;
                b {
                    font-size: 110%;
                }
            }

            .disclaimer {
                margin-top: 20px;
                font-size: 70%;
                font-style: italic;
            }
        }
    }

    .actions {
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 110%;

        button {
            width: 150px;
        }

        .progress {
            width: 60px;
            color: $primary;
            text-align: center;
        }
    }
}