@import '../../styles/shared';

.contact {
  form {
    padding: 50px 10px 0 10px;

    .small {
      width: 400px;
    }

    > div {
      margin: 40px 0px;
    }
  }

  .applyBtn {
    display: flex;

    .success {
      padding: 0 0 0 10px;
      font-size: 20px;
      display: flex;
      color: $primary;
      align-items: center;
    }
  }
}