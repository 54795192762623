@import '../../styles/shared';



.products {
    h1 {
        font-size: 42px;
        color: $neutral-dark;
        font-weight: normal;

        div {
            font-size: 42px;
            font-weight: 800;
            white-space: nowrap;
        }
    }

    strong {
        color: #DA8C9A;
        font-size: 120%;
    }

    a.externalRef {
        color: #8DB5AB;
        font-weight: bold;
    }

    a.externalRef:hover {
        text-decoration: underline;
    }

    .productSummary {
        border-left: solid 10px #8DB5AB;
        color: #555555;
        position: relative;
        padding: 10px 0 10px 0;
        margin: 50px 0 0 10px;
    }
    
    .product, .productSummary {
        h3 {
            margin: 10px 0 10px 10px;
        }

        ul {
            padding-left: 5px;
        }

        li {
            display: flex;
            align-items: center;

            svg {
                padding: 0 10px 0 0;
            }

            strong {
                width: 35px;
                font-size: 80%;
                padding: 0 10px 0 0;
                text-align: right;
            }
        }

        a {
            margin-left: 10px;
        }
    }

    .centered {
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }

    .leftButton {
        display: flex;
        padding: 0 0 10px 20px;

        @media screen and (max-width: 500px) {
            padding: 0 40px 10px 30px;
            flex-direction: column;
            gap: 10px;
        }
    }


    .signature {
        color: #DA8C9A;
        font-size: 105%;
        margin: 50px 0 50px 0;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: calc(100% - 20px);
            max-width: 300px;
        }

        strong {
            max-width: 400px;
        }
    }

    .primary {
        background-color: #8DB5AB;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;

        h2 {
            color: $neutral_light;
        }

        h3 {
            display: flex;
            align-items: center;
            color: $neutral_light;
        }

        p {
            color: $neutral-light;
            font-size: 20px;
            font-weight: 400;
        }

        a {
            margin: 0 10px;
            color: #619789 !important;
            border-color: #619789 !important;
            background-color: $neutral-light;
        }

        a:hover {
            background-color: $neutral-light;
            border-color: #C3D9D4 !important;
        }
    }

    .secondary {
        background-color: #DA8C9A;
        box-shadow: 0 0 20px 4px;
        position: relative;
        color: $neutral-dark;

        h2 {
            color: $neutral_light;
        }

        h3 {
            display: flex;
            align-items: center;
            color: $neutral_light;
        }

        p {
            color: $neutral-light;
            font-size: 20px;
            font-weight: 400;
        }

        a {
            margin: 0 10px;
            color: #943243 !important;
            border-color: #943243 !important;
            background-color: $neutral-light;
        }

        a:hover {
            background-color: $neutral-light;
            border-color: #D46A6A !important;
        }
    }

    .previewWrapper {
        padding: 10px 10px;
        display: flex;

        .previewItem {
            margin: 10px 10px 10px 10px;
            border-style: solid;
            border-width: 5px 5px 10px 5px;
            border-color: $neutral-dark;
            position: relative;
            box-shadow: 0 0 2px 2px;
            color: $neutral-dark;
            border-radius: 20px;

            img {
                margin: auto;
                display: block;
                width: 80%;
                max-height: 700px;
            }
        }
    }
}
